<template>
  <v-app>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="9" md="4" lg="9" xl="4" class="text-center">
          <div class="from-login">
            <v-card class="elevation-12 ">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login form</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn :href="source" icon large target="_blank" v-on="on">
                      <v-icon>mdi-code-tags</v-icon>
                    </v-btn>
                  </template>
                  <span>Source</span>
                </v-tooltip>
              </v-toolbar>
              <v-form  method="post" id="login-form" @submit.prevent="handleLogin">
                <v-alert v-if="error.length"
                         border="top"
                         color="red lighten-2"
                         dark
                >
                  {{ error }}
                </v-alert>
                <v-card-text>
                  <v-text-field
                      name="login"
                      prepend-icon="mdi-account"
                      type="text"
                      v-model="email"
                      placeholder="Email"
                  ></v-text-field>
                  <v-text-field
                      placeholder="Password"
                      id="password"
                      name="password"
                      prepend-icon="mdi-lock"
                      type="password"
                      v-model="password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" form="login-form" color="primary"
                  >Login</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { LOGIN } from "@/store/auth.module";
import {mapGetters} from "vuex";

export default {
  props: {
    source: String
  },
  data() {
    return {
      // Remove this dummy login info
      error: "",
      email: "",
      password: ""
    };
  },
  computed: {
    ...mapGetters({
      user: "currentUser"
    })
  },
  methods: {
    handleLogin() {
      this.$store
        .dispatch(LOGIN, {
          email: this.email,
          password: this.password
        })
        // go to which page after successfully login
        .then(data => {
            if (data.status == 0) {
                this.error = data.message;
            }
            if (data.status == 1) {
                localStorage.setItem("user_name", this.user.full_name);
                this.$router.push({ name: "dashboard" })
            }
        });
    }
  }
};
</script>
<style lang="scss">
  .from-login {
    width: 500px!important;
  }
</style>